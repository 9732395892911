import React from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { unset } from "lodash";

import {
    generalConfigStore,
    setIsPaperlessTicketActive,
    setIsEmailActive,
    setIsConfirmationEmailActive,
    setIsUnauthenticatedEmailActive,
    setItems,
    setFunctionIsPaperlessChecked,
    setIsBackgroundPrinterOptionsConfirmationEmailActive,
    setInformationPrinterOptionsConfirmationEmailItems,
    setInformationPrinterOptionsEntryEmailItems,
    setIsLogoPrinterOptionsConfirmationEmailActive,
    setIsTopBannerPrinterOptionsConfirmationEmailActive,
} from "@store";
import { ConfirmationTicket } from "./ConfirmationTicket";
export function Ticket(): JSX.Element {
    const { t } = useTranslation();
    const [isModalOpened, setIsModalOpened] = React.useState(false);

    const {
        isPaperlessTicketActive,
        isEmailActive,
        isConfirmationEmailActive,
        isUnauthenticatedEmailActive,
        project,
    } = useSnapshot(generalConfigStore);

    function setIsPaperlessChecked(
        localPagesItems: ItemsPagesType,
        printerOptionsElement: any
    ) {
        localPagesItems.elements["printerOptions"] = printerOptionsElement;
        unset(localPagesItems.ways, ["printerOptions"]);
        setIsEmailActive(false);
        setIsConfirmationEmailActive(false);
        setIsUnauthenticatedEmailActive(false);
        setItems(localPagesItems);
        setIsPaperlessTicketActive(!isPaperlessTicketActive);
    }
    const informationPrinterOptionsEntryEmailItems = ((project.template.pages
        .subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).header
        .informationMessage.entryemail;

    const informationPrinterOptionsConfirmationEmailItems = ((project.template
        .pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).header
        .informationMessage.confirmationemail;

    function handleIsPaperlessTicketActiveSwitchOnClickEvent() {
        let localPagesItems: ItemsPagesType = JSON.parse(
            JSON.stringify(project.template.pages)
        );

        let printerOptionsElement = {
            ...(isPaperlessTicketActive
                ? localPagesItems.ways["printerOptions"]
                : localPagesItems.elements["printerOptions"]),
        };

        printerOptionsElement = {
            ...printerOptionsElement,
            prefix: isPaperlessTicketActive ? "elements" : "ways",
            index:
                Object.keys(localPagesItems.ways).indexOf("finalMessage") - 1,
        };

        if (isPaperlessTicketActive === true) {
            setIsModalOpened(!isModalOpened);

            setFunctionIsPaperlessChecked(() => {
                setIsPaperlessChecked(localPagesItems, printerOptionsElement);
            });
        } else {
            localPagesItems.ways["printerOptions"] = printerOptionsElement;
            localPagesItems.ways["finalMessage"] = {
                ...localPagesItems.ways["finalMessage"],
                index: Object.keys(localPagesItems.ways).length,
            };
            localPagesItems.ways = Object.fromEntries(
                Object.entries(localPagesItems.ways).sort(
                    (a: any, b: any) => a[1].id - b[1].id
                )
            );

            unset(localPagesItems.elements, ["printerOptions"]);
            setItems(localPagesItems);

            setIsPaperlessTicketActive(!isPaperlessTicketActive);
        }
    }

    function handleIsEmailActiveSwitchOnClickEvent() {
        setIsEmailActive(!isEmailActive);
        if (isEmailActive) {
            setIsConfirmationEmailActive(false);
        }
    }
    function handleIsConfirmationEmailActiveSwitchOnClickEvent() {
        setIsConfirmationEmailActive(!isConfirmationEmailActive);
        if (isConfirmationEmailActive) {
            setIsBackgroundPrinterOptionsConfirmationEmailActive(false);
            setIsTopBannerPrinterOptionsConfirmationEmailActive(false);
            setIsLogoPrinterOptionsConfirmationEmailActive(false);
            setInformationPrinterOptionsConfirmationEmailItems({
                ...informationPrinterOptionsConfirmationEmailItems,
                active: false,
            });
            setInformationPrinterOptionsEntryEmailItems({
                ...informationPrinterOptionsEntryEmailItems,
                active: false,
            });
        }
    }
    function handleIsUnauthenticatedEmailActiveSwitchOnClickEvent() {
        setIsUnauthenticatedEmailActive(!isUnauthenticatedEmailActive);
    }
    React.useEffect(() => {
        if (
            (project.template.pages.ways[
                "printerOptions"
            ] as PrinterOptionsPageType) !== undefined
        ) {
            setIsEmailActive(
                ((project.template.pages.ways[
                    "printerOptions"
                ] as PrinterOptionsPageType)
                    .printerOptionsConfig as PrinterOptionsConfigType)
                    .isEmailActive
            );
            setIsConfirmationEmailActive(
                ((project.template.pages.ways[
                    "printerOptions"
                ] as PrinterOptionsPageType)
                    .printerOptionsConfig as PrinterOptionsConfigType)
                    .isConfirmationEmailActive
            );
            setIsUnauthenticatedEmailActive(
                ((project.template.pages.ways[
                    "printerOptions"
                ] as PrinterOptionsPageType)
                    .printerOptionsConfig as PrinterOptionsConfigType)
                    .isUnauthenticatedEmailActive
            );
        } else {
            setIsEmailActive(
                ((project.template.pages.elements[
                    "printerOptions"
                ] as PrinterOptionsPageType)
                    .printerOptionsConfig as PrinterOptionsConfigType)
                    .isEmailActive
            );
            setIsConfirmationEmailActive(
                ((project.template.pages.elements[
                    "printerOptions"
                ] as PrinterOptionsPageType)
                    .printerOptionsConfig as PrinterOptionsConfigType)
                    .isConfirmationEmailActive
            );
            setIsUnauthenticatedEmailActive(
                ((project.template.pages.elements[
                    "printerOptions"
                ] as PrinterOptionsPageType)
                    .printerOptionsConfig as PrinterOptionsConfigType)
                    .isUnauthenticatedEmailActive
            );
        }
    });
    return (
        <>
            {isModalOpened && (
                <ConfirmationTicket
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                />
            )}
            <div className="d-flex flex-column pr-4">
                <div className="d-flex align-items-center mt-3">
                    <span
                        style={{
                            font: "normal normal 600 35px/61px Nunito Sans",
                        }}
                    >
                        {t("Paperless ticket")}
                    </span>
                </div>

                <div
                    className="d-flex flex-column pl-4"
                    style={{ gap: "10px" }}
                >
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        {t("Activate paperless ticketing")}

                        <ReactSwitch
                            className="ksk_chk_expertTicketDematerialize"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isPaperlessTicketActive}
                            onChange={() => {
                                handleIsPaperlessTicketActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>

                    <hr className="w-100" />
                    <span
                        style={{
                            font: "normal normal 600 24px/40px Nunito Sans",
                        }}
                    >
                        {t("Authenticated customer with valid e-mail address")}
                    </span>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        {t("Activate the « Send receipt by e-mail » option:")}

                        <ReactSwitch
                            className="ksk_chk_expertAuthSendTicketInEmail"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isEmailActive}
                            onChange={() => {
                                handleIsEmailActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                            disabled={!isPaperlessTicketActive}
                        />
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        {t(
                            "Activate the «Check e-mail address » option before sending the ticket:"
                        )}

                        <ReactSwitch
                            className="ksk_chk_expertAuthCheckEmail"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isConfirmationEmailActive}
                            onChange={() => {
                                handleIsConfirmationEmailActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                            disabled={
                                !isEmailActive || !isPaperlessTicketActive
                            }
                        />
                    </div>
                    <hr className="w-100" />
                    <span
                        style={{
                            font: "normal normal 600 24px/40px Nunito Sans",
                        }}
                    >
                        {t("Unauthenticated customer")}
                    </span>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        {t("Activate the “Send receipt by email” option:")}

                        <ReactSwitch
                            className="ksk_chk_expertNotAuthSendEmail"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isUnauthenticatedEmailActive}
                            onChange={() => {
                                handleIsUnauthenticatedEmailActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                            disabled={!isPaperlessTicketActive}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
