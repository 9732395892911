import { proxy, subscribe } from "valtio";
import { proxyWithComputed } from "valtio/utils";
import moment from "moment";

import { AuthenticationModesContent } from "@pages/GeneralConfig/Design/Content/AuthenticationModes";
import { ConnectionContent } from "@pages/GeneralConfig/Design/Content/Connection";
import { FinalMessageContent } from "@pages/GeneralConfig/Design/Content/FinalMessage";
import { InformationModesContent } from "@pages/GeneralConfig/Design/Content/InformationsModes";
import { LoyaltyConsommationContent } from "@pages/GeneralConfig/Design/Content/LoyaltyConsommation";
import { OrderTakingContent } from "@pages/GeneralConfig/Design/Content/OrderTaking";
import { PaymentContent } from "@pages/GeneralConfig/Design/Content/Payment";
import { MeansOfPaymentContent } from "@pages/GeneralConfig/Design/Content/MeansOfPayment";
import { SalesMethodsContent } from "@pages/GeneralConfig/Design/Content/SalesMethods";
import { StandbyScreenContent } from "@pages/GeneralConfig/Design/Content/StandbyScreen";
import { AuthenticationModesPreview } from "@pages/GeneralConfig/Design/Preview/AuthenticationModes";
import { ConnectionPreview } from "@pages/GeneralConfig/Design/Preview/Connection";
import { FinalMessagePreview } from "@pages/GeneralConfig/Design/Preview/FinalMessage";
import { InformationModesPreview } from "@pages/GeneralConfig/Design/Preview/InformationModes";
import { LoyaltyConsommationPreview } from "@pages/GeneralConfig/Design/Preview/LoyaltyConsommation";
import { OrderTakingPreview } from "@pages/GeneralConfig/Design/Preview/OrderTaking";
import { PaymentPreview } from "@pages/GeneralConfig/Design/Preview/Payment";
import { MeansOfPaymentPreview } from "@pages/GeneralConfig/Design/Preview/MeansOfPayment";
import { SalesMethodsPreview } from "@pages/GeneralConfig/Design/Preview/SalesMethods";
import { StandbyScreenPreview } from "@pages/GeneralConfig/Design/Preview/StandbyScreenPreview";
import { PrinterOptionsContent } from "@pages/GeneralConfig/Design/Content/PrinterOptions";
import { PrinterOptionsPreview } from "@pages/GeneralConfig/Design/Preview/PrinterOptions";

type StoreType = {
    project: ProjectType;
    templates: NewTemplateType[];
    getTemplates: () => Promise<void>;
    chosenTemplate: NewTemplateType;
    visualizedTemplate: NewTemplateType;
    isModalOpened: boolean;
    isWaysTabActive: boolean;
    transitions: TransitionsType;
    getTransitions: () => Promise<void>;
    getItemsDisable: () => Promise<void>;
    chosenTransition: TransitionType;
    generalTransition: TransitionType;
    isAnimationIn: boolean;
    activeSubTab: number;
    defaultLanguage: LanguagesItemType;
    userLanguage: string;
    selectedActionsButtonsLanguage: string;
    isCreateItemStandbyScreenClicked: boolean;
    selectedStandbyScreenItemId: string;
    isModesModalOpened: boolean;
    isCustomerAccountActive: boolean;
    selectedSaleModeValue: string;
    isSpecificTransitionChosen: boolean;
    isOrderTakingTopBannerActive: boolean;
    isOrderTakingTagsActive: boolean;
    isResetOneImageButtonClicked: boolean;
    isResetModalOpened: boolean;
    uploadedFiles: UploadedFilesType[];
    imageKey: string;
    generalChosenTransition: string;
    isAllergenActive: boolean;
    isPRMActive: boolean;
    isHelpActive: boolean;
    isCaloryActive: boolean;
    isOrderTakingCategoryTitleActive: boolean;
    isOrderTakingSelectedCategoryTitleActive: boolean;
    isOrderTakingProductInformationActive: boolean;
    activeStateScreenItems: StandbyScreenItemType[];
    defaultActiveStateScreenItems: StandbyScreenItemType[];
    isDeleteActiveStateScreenItemModalOpened: boolean;
    standbyStateScreenItems: StandbyScreenItemType[];
    defaultStandbyStateScreenItems: StandbyScreenItemType[];
    isDeleteStandbyStateScreenItemModalOpened: boolean;
    activeAdvertisingScreenTab: number;
    deletedActiveStateScreenItemId: string;
    deletedStandbyStateScreenItemId: string;
    secondColor: string;
    selectedCategoryActive: boolean;
    isConfigActive: boolean;
    selectSubStepOrderTaking: string;
    isProjectSaveSuccessfully: boolean;
    isUploadRequestInProgress: boolean;
    isBackgroundSummaryActive: boolean;
    isCustomerAccountOrderTakingActive: boolean;
    isAllergenOrderTakingActive: boolean;
    isOrderTakingCaloryActive: boolean;
    isStateChanged: boolean;
    selectedOptionsLanguage: string;
    isInformationModesEmpty: boolean;
    initItemsFromGeneralSetting: {
        informationModes: ProjectMainContentItemType[];
        saleMethods: ProjectMainContentItemType[];
        reglements: ProjectMainContentItemType[];
        languages: LanguagesItemType[];
        logo: { name: string; content: string; id: string };
    };
    defaultWays: ItemsPagesType;
    isConfigured: boolean;
    isOrderTakingPromoCodeActive: boolean;
    activeStandbyScreenFile: StandbyScreenItemType[];
    standbyScreenFile: StandbyScreenItemType[];
    isCommentArticleActive: boolean;
    isFreeCommentActive: boolean;
    isCommentRecoveryActive: boolean;
    isPaperlessTicketActive: boolean;
    isEmailActive: boolean;
    isConfirmationEmailActive: boolean;
    isUnauthenticatedEmailActive: boolean;
    functionIsPaperlessChecked: Function;
    files: ImageItemType[];
    isShopCartDetailsActive: boolean;
    designCategories: string | number;
    isNutriscoreActive: boolean;
    isNutriscoreInCompositeProductActive: boolean;
    isNutriscoreOnTheProductActive: boolean;
    isNutriscoreInTheInformationPopUpActive: boolean;
    isBorderCardProductActive: boolean;
    selectedActionsButtons1Language: string;
    selectedActionsButtons2Language: string;
    isFieldRequiredEmpty: boolean;
    isCoverImageActive: boolean;
    itemsDisable: any;
    isMinMaxShown: boolean;
    accountFidelity: any;
};

export const store = proxy<StoreType>({
    project: {
        projectId: 0,
        name: "",
        associates: {},
        isArchived: false,
        Status: "en attente",
        modified_at: `le ${moment().format("DD/MM/YY à HH:mm a")}`,
        copy_status: false,
        hour: `${moment().format("DD/MM/YY - HH:mm a")}`,
        template: {
            id: "",
            name: "",
            coverImage: "",
            pages: {
                elements: {},
                ways: {},
                subSteps: {},
            },
            content: {
                salesMethods: { items: [] },
                meansOfPayment: { items: [] },
                informationModes: { items: [] },
                printerOptions: { items: [] },
                options: { active: true, items: [] },
                actionsButtons: {
                    active: true,
                    items: [
                        {
                            id: "",
                            name: "",
                            active: true,
                            languages: {
                                fr: {
                                    content: "",
                                },
                                en: {
                                    content: "",
                                },
                            },
                            role: 1,
                        },
                    ],
                },
                languages: {
                    active: true,
                    items: [
                        {
                            id: "",
                            flag: "",
                            name: "",
                            title: "",
                            language: "",
                            active: true,
                            isDefault: false,
                        },
                    ],
                },
                generalDesign: {
                    logo: {
                        id: "",
                        name: "",
                        content: "l",
                    },
                    prm: {
                        id: "",
                        name: "",
                        content: "l",
                        defaultContent: "",
                        type: "",
                        timeout: 0,
                    },
                    colors: [],
                    fonts: [],
                },
            },
            timeout: 15,
            initialStepTimeout: 10,
            keyboard: "azerty",
            autoLaunch: true,
        },
        files: [
            {
                name: "",
                id: "",
                content: "",
                type: "",
                timeout: 0,
                key: "",
                subName: "",
                defaultImage: "",
            },
        ],
        note: "",
        designation: "",
        hoursOfUse: "",
        shopId: "0",
    },
    templates: [],
    getTemplates: async function () {
        if (
            localStorage.getItem("templates") !== null &&
            JSON.parse(localStorage.getItem("templates") as string).length !== 0
        ) {
            store.templates = JSON.parse(
                localStorage.getItem("templates") as string
            );
        } else {
            const response: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/templates`
            );
            const result = await response.json();
            const templates: NewTemplateType[] = result.data;
            store.templates = templates;
        }
    },
    chosenTemplate: {
        id: "",
        name: "",
        coverImage: "",
        pages: {
            elements: {},
            ways: {},
            subSteps: {},
        },
        content: {
            salesMethods: { items: [] },
            meansOfPayment: { items: [] },
            informationModes: { items: [] },
            options: { active: true, items: [] },
            actionsButtons: {
                active: true,
                items: [
                    {
                        id: "",
                        name: "",
                        active: true,
                        languages: {
                            fr: {
                                content: "",
                            },
                            en: {
                                content: "",
                            },
                        },
                        role: 1,
                    },
                ],
            },
            languages: {
                active: true,
                items: [
                    {
                        id: "",
                        flag: "",
                        name: "",
                        title: "",
                        language: "",
                        active: true,
                        isDefault: false,
                    },
                ],
            },
            generalDesign: {
                logo: {
                    id: "",
                    name: "",
                    content: "l",
                },
                prm: {
                    id: "",
                    name: "",
                    content: "l",
                    defaultContent: "",
                    type: "",
                    timeout: 0,
                },
                colors: [],
                fonts: [],
            },
        },
        timeout: 20,
        initialStepTimeout: 10,
        keyboard: "azerty",
        autoLaunch: true,
    },
    visualizedTemplate: {
        id: "",
        name: "",
        coverImage: "",
        pages: {
            elements: {},
            ways: {},
            subSteps: {},
        },
        content: {
            salesMethods: { items: [] },
            meansOfPayment: { items: [] },
            informationModes: { items: [] },
            options: { active: true, items: [] },
            actionsButtons: {
                active: true,
                items: [
                    {
                        id: "",
                        name: "",
                        active: true,
                        languages: {
                            fr: {
                                content: "",
                            },
                            en: {
                                content: "",
                            },
                        },
                        role: 1,
                    },
                ],
            },
            languages: {
                active: true,
                items: [
                    {
                        id: "",
                        flag: "",
                        name: "",
                        title: "",
                        language: "",
                        active: true,
                        isDefault: false,
                    },
                ],
            },
            generalDesign: {
                logo: {
                    id: "",
                    name: "",
                    content: "l",
                },
                prm: {
                    id: "",
                    name: "",
                    content: "l",
                    defaultContent: "",
                    type: "",
                    timeout: 0,
                },
                colors: [],
                fonts: [],
            },
        },
        timeout: 15,
        initialStepTimeout: 10,
        keyboard: "azerty",
        autoLaunch: true,
    },
    isModalOpened: false,
    activeSubTab: 0,
    isWaysTabActive: false,
    transitions: {},
    getTransitions: async function () {
        if (localStorage.getItem("transitions") !== null) {
            // eslint-disable-next-line
            store.transitions = JSON.parse(
                localStorage.getItem("transitions")!
            );
        } else {
            const response: Response = await fetch("/transitions.json");
            const transitions = await response.json();
            store.transitions = transitions;
        }
    },
    getItemsDisable: async function () {
        if (localStorage.getItem("itemsDisable") !== null) {
            // eslint-disable-next-line
            store.itemsDisable = JSON.parse(
                localStorage.getItem("itemsDisable")!
            );
        }
    },
    chosenTransition: { prettyName: "", timeout: 0, className: "" },
    generalTransition: { prettyName: "None", timeout: 0, className: "none" },
    isAnimationIn: false,
    defaultLanguage: {
        id: "",
        flag: "",
        name: "",
        title: "",
        language: "",
        active: false,
        isDefault: false,
    },
    userLanguage: "fr",
    selectedActionsButtonsLanguage: "fr",
    isCreateItemStandbyScreenClicked: false,
    selectedStandbyScreenItemId: "",
    isModesModalOpened: false,
    isCustomerAccountActive: false,
    selectedSaleModeValue: "",
    isSpecificTransitionChosen: false,
    isOrderTakingTopBannerActive: false,
    isOrderTakingTagsActive: false,
    isResetOneImageButtonClicked: false,
    isResetModalOpened: false,
    uploadedFiles: [],
    imageKey: "",
    generalChosenTransition: "",
    isAllergenActive: false,
    isOrderTakingCategoryTitleActive: false,
    isOrderTakingSelectedCategoryTitleActive: false,
    isOrderTakingProductInformationActive: false,
    activeStateScreenItems: [],
    defaultActiveStateScreenItems: [],
    isDeleteActiveStateScreenItemModalOpened: false,
    standbyStateScreenItems: [],
    defaultStandbyStateScreenItems: [],
    isDeleteStandbyStateScreenItemModalOpened: false,
    activeAdvertisingScreenTab: 0,
    deletedActiveStateScreenItemId: "",
    deletedStandbyStateScreenItemId: "",
    secondColor: "",
    selectedCategoryActive: false,
    isConfigActive: false,
    selectSubStepOrderTaking: "",
    isProjectSaveSuccessfully: false,
    isUploadRequestInProgress: false,
    isBackgroundSummaryActive: false,
    isCustomerAccountOrderTakingActive: false,
    isAllergenOrderTakingActive: false,
    isPRMActive: false,
    isHelpActive: false,
    isCaloryActive: false,
    isOrderTakingCaloryActive: false,
    isStateChanged: false,
    selectedOptionsLanguage: "fr",
    isInformationModesEmpty: false,
    initItemsFromGeneralSetting: {
        informationModes: [],
        saleMethods: [],
        reglements: [],
        languages: [],
        logo: { name: "", content: "", id: "" },
    },
    defaultWays: {},
    isConfigured: false,
    isOrderTakingPromoCodeActive: false,
    activeStandbyScreenFile: [],
    standbyScreenFile: [],
    isCommentArticleActive: false,
    isFreeCommentActive: false,
    isCommentRecoveryActive: false,
    isPaperlessTicketActive: true,
    isEmailActive: true,
    isConfirmationEmailActive: false,
    isUnauthenticatedEmailActive: false,
    functionIsPaperlessChecked: () => {},
    files: [],
    isShopCartDetailsActive: false,
    designCategories: "" as string,
    isNutriscoreActive: false,
    isNutriscoreInCompositeProductActive: false,
    isNutriscoreOnTheProductActive: false,
    isNutriscoreInTheInformationPopUpActive: false,
    isBorderCardProductActive: true,
    selectedActionsButtons1Language: "fr",
    selectedActionsButtons2Language: "fr",
    isFieldRequiredEmpty: false,
    isCoverImageActive: false,
    itemsDisable: [],
    isMinMaxShown: false,
    accountFidelity: {},
});

export const computedStore = proxyWithComputed(
    {
        items: store.project.template.pages,
    },
    {
        elements: (snap) =>
            (Object.values(snap.items["ways"]).length > 0
                ? (Object.values(snap.items["ways"]) as (
                      | PageType
                      | ConsommationPageType
                      | StandbyScreenPageType
                      | PaymentPageType
                      | OrderTakingPageType
                      | PrinterOptionsPageType
                  )[])
                : (Object.values(snap.items["elements"]) as (
                      | PageType
                      | ConsommationPageType
                      | StandbyScreenPageType
                      | PaymentPageType
                      | OrderTakingPageType
                      | PrinterOptionsPageType
                  )[])
            )
                .filter(
                    (item) =>
                        !["informationModesConsommation"].includes(item.name)
                )
                .map(
                    (
                        item:
                            | PageType
                            | ConsommationPageType
                            | StandbyScreenPageType
                            | PaymentPageType
                            | OrderTakingPageType
                            | PrinterOptionsPageType
                    ) => {
                        if (item.name === "salesMethods") {
                            return {
                                ...item,
                                designContent: SalesMethodsContent,
                                designPreview: SalesMethodsPreview,
                            };
                        } else if (item.name === "meansOfPayment") {
                            return {
                                ...item,
                                designContent: MeansOfPaymentContent,
                                designPreview: MeansOfPaymentPreview,
                            };
                        } else if (item.name === "informationModes") {
                            return {
                                ...item,
                                designContent: InformationModesContent,
                                designPreview: InformationModesPreview,
                                name: "informationModes",
                            };
                        } else if (item.name === "authenticationModes") {
                            return {
                                ...item,
                                designContent: AuthenticationModesContent,
                                designPreview: AuthenticationModesPreview,
                                name: "authenticationModes",
                            };
                        } else if (item.name === "connection") {
                            return {
                                ...item,
                                designContent: ConnectionContent,
                                designPreview: ConnectionPreview,
                                name: "connection",
                            };
                        } else if (item.name === "loyaltyConsommation") {
                            return {
                                ...item,
                                designContent: LoyaltyConsommationContent,
                                designPreview: LoyaltyConsommationPreview,
                                animation: "",
                            };
                        } else if (item.name === "payment") {
                            return {
                                ...item,
                                designContent: PaymentContent,
                                designPreview: PaymentPreview,
                                animation: "",
                                name: "payment",
                            };
                        } else if (item.name === "finalMessage") {
                            return {
                                ...item,
                                designContent: FinalMessageContent,
                                designPreview: FinalMessagePreview,
                                name: "finalMessage",
                            };
                        } else if (item.name === "standbyScreen") {
                            return {
                                ...item,
                                designContent: StandbyScreenContent,
                                designPreview: StandbyScreenPreview,
                                name: "standbyScreen",
                            };
                        } else if (item.name === "orderTaking") {
                            return {
                                ...item,
                                designContent: OrderTakingContent,
                                designPreview: OrderTakingPreview,
                                name: "orderTaking",
                            };
                        } else if (item.name === "printerOptions") {
                            return {
                                ...item,
                                designContent: PrinterOptionsContent,
                                designPreview: PrinterOptionsPreview,
                                name: "printerOptions",
                            };
                        } else {
                            return {
                                ...item,
                                designContent: SalesMethodsContent,
                                designPreview: SalesMethodsPreview,
                            };
                        }
                    }
                ),
    }
);

store.getTemplates();
store.getTransitions();
store.getItemsDisable();
subscribe(store, () => {
    localStorage.setItem("project", JSON.stringify(store.project));
    localStorage.setItem("templates", JSON.stringify(store.templates));
    localStorage.setItem("transitions", JSON.stringify(store.transitions));
});
