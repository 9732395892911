import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { uid } from "react-uid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    generalConfigStore,
    setCustomerAccountOptionsItems,
    setImageKey,
    setProjectFiles,
} from "@store";

import { ResetModalWrapper } from "@components/Common/ResetModalWrapper";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";

import { CustomerAccountDragDropWrapper } from "../CustomerAccountDragDropWrapper";

export function CustomerAccountOptions(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files: images },
        templates,
        isResetModalOpened,
        defaultLanguage,
    } = useSnapshot(generalConfigStore);

    const [customerAccountOptions, setCustomerAccountOptions] = React.useState<
        CustomerAccountItemType[]
    >(
        // eslint-disable-next-line
        (template.content.options as OptionsType).items.filter(
            (item: OptionsItemType) => item.title === "Loyalty account"
        )[0].customerAccountOptions!
    );
    const [
        localIsResetModalOpened,
        setLocalIsResetModalOpened,
    ] = React.useState<boolean>(isResetModalOpened);

    const initCustomerAccountOptions = (templates.filter(
        (element) => element.id === template.id
    )[0].content.options as OptionsType).items;

    function handleResetOnClickEvent() {
        setCustomerAccountOptions(
            // eslint-disable-next-line
            initCustomerAccountOptions.filter(
                (item: OptionsItemType) => item.title === "Loyalty account"
            )[0].customerAccountOptions!
        );

        const imagesArray = [...images];
        customerAccountOptions
            .map((item) => item.id)
            .forEach((id) => {
                const image = imagesArray.find((element) => element.id === id);

                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    const localContent = image.content as ImageItemLanguagesType;

                    imagesArray[imageIndex] = {
                        ...image,
                        content: {
                            ...localContent,
                            [defaultLanguage.name]: {
                                ...localContent[defaultLanguage.name],
                                path:
                                    localContent[defaultLanguage.name]
                                        .defaultImage,
                            },
                        } as ImageItemLanguagesType,
                    };
                }
            });

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    return (
        <React.Fragment>
            <div
                className="h-100 "
                style={{
                    display: "grid",
                    gridTemplateRows: "0.1fr 0.9fr",
                }}
            >
                <FlexboxGrid
                    alignItemsCentered={true}
                    className="justify-content-between cursor__clz"
                >
                    <span
                        style={{
                            font: "normal normal 600 35px/61px Nunito Sans",
                        }}
                    >
                        {t("Options")}
                    </span>
                    <StyledIconButton
                        title={t("Reset to default settings")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0 disable-account-creation-items__clz"
                        icon="RefreshIcon"
                        style={{ height: "20%" }}
                        onClick={() => {
                            setLocalIsResetModalOpened(
                                !localIsResetModalOpened
                            );
                        }}
                    >
                        <RefreshIcon
                            height={20}
                            width={20}
                            className="Cmn_btn_resetModifications"
                        />
                    </StyledIconButton>
                </FlexboxGrid>
                <div className="disable-account-creation-items__clz">
                    <CustomerAccountDragDropWrapper
                        items={customerAccountOptions}
                        setItems={setCustomerAccountOptions}
                        setConfig={setCustomerAccountOptionsItems}
                    />
                </div>
            </div>
            <ResetModalWrapper
                handleResetOnClickEvent={handleResetOnClickEvent}
                localIsResetModalOpened={localIsResetModalOpened}
                setLocalIsResetModalOpened={setLocalIsResetModalOpened}
            />
        </React.Fragment>
    );
}
