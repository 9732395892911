import React from "react";
import { useSnapshot } from "valtio";

import {
    computedStore,
    generalConfigStore,
    designStore,
    setIsLanguagesActive,
    setIsConfigOptionsActive,
    setActiveConfigOptions,
    setActiveLanguages,
    setPaymentMainContent,
    setPaymentSelectedRegulationModeValue,
    setActivePageName,
    setSecondColor,
    setSelectedCategory,
    setShopCardMessage,
    setCompositeProductStepShopCardMessage,
    setCalorieIcon,
    setIsAllergenOrderTakingActive,
    setIsBackgroundActive,
    setIsBackgroundSummaryActive,
    setIsCategorySubCategoryTitleActive,
    setIsClosingButtonActive,
    setIsCustomerAccountOrderTakingActive,
    setIsHeaderOrderTakingActive,
    setIsOrderTakingCaloryActive,
    setIsProductDescriptionActive,
    setIsProductTitleActive,
    setIsSummaryHeaderAccumulatedLoyaltyPointsActive,
    setIsSummaryHeaderLogoActive,
    setIsUserAccountOrderTakingActive,
    setProductAccessIcon,
    setSummaryHeaderAccumulatedLoyaltyPointsItems,
    setSummaryHeaderItems,
    setIsSalesMethodChangeInSummaryActive,
    setIsSalesMethodChangeActive,
    setIsNutriscoreInTheInformationPopUpActive,
    setIsNutriscoreOnTheProductActive,
    setIsNutriscoreActive,
    setIsBorderCardProductActive,
    setFiles,
    setIsSpecificTransitionChosen,
    setGeneralTransition,
    setIsCoverImageActive,
    // setInformationPrinterOptionsWithEmailItems,
} from "@store";

import { WaysSwiperWrapper } from "@components/Common/Design/WaysSwiperWrapper";
import { moveArrayElementToTheBegin } from "@helpers/general";

import { data } from "./Content/OrderTaking/fastFoodCard";

export function Design(): JSX.Element {
    const {
        activeSlideIndex,
        activePageName,
        selectedLanguage,
        paymentSelectedRegulationModeValue,
        selectedRegulationModeValue,
        selectedInformationModeValue,
    } = useSnapshot(designStore);
    const {
        project: { template, files },
        selectedSaleModeValue,
        isCustomerAccountActive,
        isCaloryActive,
    } = useSnapshot(generalConfigStore);

    const { elements } = useSnapshot(computedStore);

    React.useEffect(() => {
        if (elements.length > 0 && elements[activeSlideIndex] !== undefined) {
            setPaymentSelectedRegulationModeValue(
                (template.content.payment as PaymentContentItemType).items[0]
                    .shortName
            );

            setPaymentMainContent(
                ((template.content as ProjectContentType)[
                    "payment"
                ] as PaymentContentItemType).items
            );
            setFiles(files);
        }
        // eslint-disable-next-line
    }, [template.id, activePageName]);

    React.useEffect(() => {
        if (elements.length > 0 && elements[activeSlideIndex] !== undefined) {
            const activeLanguages = ((template.content as ProjectContentType)
                .languages as LanguagesType)?.items.filter((language) => {
                return language.active === true;
            });

            setActiveLanguages(
                moveArrayElementToTheBegin(
                    activeLanguages.filter(
                        (element: LanguagesItemType): boolean =>
                            element.isDefault === true
                    )[0],
                    activeLanguages
                )
            );
            setSelectedCategory(data.categories[0]);
            setSecondColor(
                ((template.content as ProjectContentType)[
                    "generalDesign"
                ] as GeneralDesignType).colors[1].content
            );

            setIsLanguagesActive(
                ((template.content as ProjectContentType)
                    .languages as LanguagesType)?.active &&
                    ((template.content as ProjectContentType)
                        .languages as LanguagesType)?.items.filter(
                        (option) => option.active === true
                    ).length > 1
            );

            setIsConfigOptionsActive(
                ((template.content as ProjectContentType)
                    .options as OptionsType)?.active
            );
            setActiveConfigOptions(
                ((template.content as ProjectContentType)
                    .options as OptionsType)?.items.filter((option) => {
                    return option.active === true && option.title !== "Usual";
                })
            );

            // if (elements[activeSlideIndex].name === "printerOptions") {
            //     setInformationPrinterOptionsWithEmailItems(
            //         (((template.pages.ways[
            //             "printerOptions"
            //         ] as PrinterOptionsPageType)
            //             .header as PrinterOptionsNewHeaderType)
            //             .informationMessage as PrinterOptionsInformationMessageType)
            //             .withemail
            //     );
            // }
            if (
                (template.pages.ways["orderTaking"] as OrderTakingPageType) !==
                undefined
            ) {
                setShopCardMessage(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .options.shopCardMessage.languages
                );
                setCompositeProductStepShopCardMessage(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .compositeProduct.shopCardMessage.languages
                );

                setIsHeaderOrderTakingActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .header.active
                );
                setIsProductTitleActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.isProductTitleActive
                );

                setIsProductDescriptionActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.isProductDescriptionActive
                );

                setCalorieIcon(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.calorieIcon
                );

                setIsBackgroundActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .background.active
                );
                setIsCategorySubCategoryTitleActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.categorySubCategoryTitle
                );

                setIsSummaryHeaderLogoActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .summary.summaryHeader.logo.active
                );
                setIsSummaryHeaderAccumulatedLoyaltyPointsActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .summary.summaryHeader.accumulatedLoyaltyPoints.active
                );
                setSummaryHeaderAccumulatedLoyaltyPointsItems(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .summary.summaryHeader.accumulatedLoyaltyPoints.items
                );
                setSummaryHeaderItems(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .summary.summaryHeader.languages
                );
                setIsSalesMethodChangeActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.salesMethodsChangeActive
                );
                setIsSalesMethodChangeInSummaryActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .summary.salesMethodsChangeActive
                );
                setIsBackgroundSummaryActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .summary.background.active
                );

                setIsNutriscoreOnTheProductActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.isNutriscoreOnTheProductActive
                );
                setIsNutriscoreInTheInformationPopUpActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct
                        .isNutriscoreInTheInformationPopUpActive
                );
                setIsNutriscoreActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.isNutriscoreActive
                );
                if (isCustomerAccountActive === false) {
                    setIsCustomerAccountOrderTakingActive(false);
                    setIsUserAccountOrderTakingActive(false);
                } else {
                    setIsCustomerAccountOrderTakingActive(
                        (template.pages.ways[
                            "orderTaking"
                        ] as OrderTakingPageType).design.items.filter(
                            (item) => item.name === "Loyalty account"
                        )[0].active
                    );
                    setIsUserAccountOrderTakingActive(
                        (template.pages.ways[
                            "orderTaking"
                        ] as OrderTakingPageType).header.userAccount.active
                    );
                }

                setIsAllergenOrderTakingActive(
                    (template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).design.items.filter(
                        (item) => item.name === "Allergen"
                    )[0].active
                );

                setIsOrderTakingCaloryActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.calorieIcon.active
                );

                setIsClosingButtonActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .compositeProduct.closingButton.active
                );
                setProductAccessIcon(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.productAccessIcon
                );
                setIsBorderCardProductActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.isBorderCardProductActive
                );

                const isAnimationSame = Object.keys(template.pages.ways).map(
                    (key: any) => {
                        return (template.pages.ways[key] as
                            | PageType
                            | ConsommationPageType
                            | PaymentPageType
                            | PrinterOptionsPageType).animation;
                    }
                );

                if (
                    isAnimationSame.every((currentValue) => currentValue) ===
                    false
                ) {
                    setGeneralTransition({
                        prettyName: "",
                        timeout: 0,
                        className: "",
                    });
                    setIsSpecificTransitionChosen(true);
                } else {
                    setIsSpecificTransitionChosen(false);
                }
                setIsCoverImageActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.isCoverImageActive
                );
            }
        }
    }, [
        activeSlideIndex,
        elements,
        selectedSaleModeValue,
        template,
        selectedLanguage,
        paymentSelectedRegulationModeValue,
        activePageName,
        selectedRegulationModeValue,
        selectedInformationModeValue,
        isCustomerAccountActive,
        isCaloryActive,
    ]);

    const ComponentContent: () => JSX.Element =
        elements[activeSlideIndex]?.designContent;

    const ComponentPreview: () => JSX.Element =
        elements[activeSlideIndex]?.designPreview;

    if (elements.length !== 0) {
        setActivePageName(elements[activeSlideIndex]?.name);
    }

    return (
        <div className="design-container__clz px-2">
            <div
                className="d-flex flex-column pt-2"
                style={{
                    height: "742px",
                }}
            >
                <div
                    style={{
                        backgroundColor: "white",
                    }}
                    className="border rounded"
                >
                    <WaysSwiperWrapper />
                </div>
                {elements.length > 0 ? <ComponentContent /> : null}
            </div>
            {elements.length > 0 ? <ComponentPreview /> : null}
        </div>
    );
}
