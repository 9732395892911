import React from "react";
import ReactSwitch from "react-switch";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { SettingsIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    designStore,
    setProjectMainContentItems,
    setMainContentItems,
    setIsSubStepActivated,
    setSelectedSubStep,
} from "@store";

import "@components/Common/Design/index.css";
import { SubStepContent } from "./SubStepContent";

export function MainContentWrapper() {
    const { t } = useTranslation();
    const { mainContentItems, isSubStepActivated } = useSnapshot(designStore);

    function handleActivateSwitchOnClickEvent(id: string) {
        const array = [...mainContentItems];

        const element = array.find((element) => element.id === id);
        console.log({ element });
        if (element !== undefined) {
            const itemIndex = array.indexOf(element);

            array[itemIndex] = {
                ...element,
                active: !array[itemIndex].active,
            };

            setMainContentItems(array);
            setProjectMainContentItems("connection", array);
        }
    }

    console.log({ mainContentItems });
    return (
        <React.Fragment>
            {isSubStepActivated === true ? (
                <SubStepContent />
            ) : (
                <div
                    className="mt-4 rounded border"
                    style={{
                        borderColor: "#CECECE",
                        marginRight: "60px",
                    }}
                >
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            fontSize: "20px",
                            backgroundColor: "#EDEDED",
                            height: "40px",
                        }}
                    >
                        <div className="mx-3 cursor__clz">
                            {t("Connection")}
                        </div>
                    </div>

                    <div
                        className="px-4 py-2"
                        style={{
                            display: "grid",
                        }}
                    >
                        {mainContentItems
                            .filter(
                                (item: ProjectMainContentItemType) =>
                                    item.name === "Create an account"
                            )
                            .map(
                                (
                                    item: ProjectMainContentItemType,
                                    index: number
                                ) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className=" switch-items-with-sub-steps__clz"
                                        >
                                            <span>{t(item.name)}</span>
                                            <StyledIconButton
                                                icon="SettingsIcon"
                                                className="m-0"
                                                onClick={() => {
                                                    setIsSubStepActivated(
                                                        !isSubStepActivated
                                                    );
                                                    setSelectedSubStep(item);
                                                }}
                                            >
                                                <SettingsIcon
                                                    height={25}
                                                    width={25}
                                                />
                                            </StyledIconButton>

                                            <div
                                                className={classNames(
                                                    "d-flex justify-content-end"
                                                )}
                                            >
                                                <ReactSwitch
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    handleDiameter={26}
                                                    offColor="#f7b4b8"
                                                    offHandleColor="#E30613"
                                                    checked={
                                                        mainContentItems[index]
                                                            .active as boolean
                                                    }
                                                    onChange={() => {
                                                        handleActivateSwitchOnClickEvent(
                                                            item.id
                                                        );
                                                    }}
                                                    onColor="#c2eddd"
                                                    onHandleColor="#34C38F"
                                                    width={50}
                                                    height={20}
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
