import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    setLocalShop,
    setNumberMultiShopActive,
    setNumberMultiShopArchived,
    store as generalSettingStore,
    setIdsLocalShop,
    setIsAssociatedLangues,
    setShopsLanguagesData,
    setShopRowData,
    setAssociatedLanguages,
    setGlobalDataIsUpdated,
} from "../store";

import { CustomIcons } from "./CustomIcons";
import { CustomSideFilter } from "./CustomSideFilter";
import { History } from "../History";
import { LanguageAssociateModal } from "./LanguageAssociateModal";
import { ConfirmationAssociatedMessage } from "../ConfirmationAssociatedMessage";
import { DisplayName } from "../DisplayName";
import { ConfirmationArchiveLanguage } from "../ConfirmationArchiveLanguage";
import NProgressWrapper from "@components/Common/NProgressWrapper";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "../index.css";

export function LanguagesShop({ setLimit }: any) {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [
        isArchivedLanguagesClicked,
        setIsArchivedLanguagesClicked,
    ] = React.useState(false);
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(store);
    const {
        isAssociateModalOpened,
        isAssociatedLangues,
        globalDataIsUpdated,
        isValidateShopArchiveOpened,
        key,
        isLoading,
    } = useSnapshot(generalSettingStore);

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [rowData, setRowData] = useState<any>({});
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    const [, setShopId] = React.useState<number | string>(shopID);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataTable, setDataTable] = React.useState<any>({});
    const uuidUser = localStorage.getItem("uuidUser");

    const urlArchive = `${process.env.REACT_APP_API_V2_URL}/settings/general/archived_language?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`;
    const urlActive = `${process.env.REACT_APP_API_V2_URL}/settings/general/language?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Language"),
            customJsx: (e: any) => <NumberLangues e={e} />,
            filterName: t("Language"),
            canFilter: true,
        },
        {
            indexOFColumn: 3,
            columnName: t("Display name"),
            customJsx: (e: any) => <DisplayName e={e} />,
            filterName: t("Display name"),
            canFilter: true,
        },
        {
            indexOFColumn: 6,
            columnName: t("Country"),
            customJsx: (e: any) => <GetCountry e={e} />,
            filterName: t("Country"),
            canFilter: true,
        },
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setShopId={setShopId}
                    setRowData={setRowData}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    isArchivedLanguagesClicked={isArchivedLanguagesClicked}
                />
            ),
        },
    ];

    function NumberLangues(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined ? (
                    <h6 className="badge__clz lan_icn_BTcounter">
                        {e.e.selectedRow[t("Shop")] !== ""
                            ? e.e.selectedRow.subRows?.length
                            : 0}
                    </h6>
                ) : (
                    e.e.selectedRow[t("Language")]
                )}
            </React.Fragment>
        );
    }

    function GetCountry(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined
                    ? e.e.selectedRow.pays
                    : e.e.selectedRow[t("Country")]}
            </React.Fragment>
        );
    }

    const ActiveArchiveCount = () => {
        let sumActive = 0;
        let sumArchive = 0;
        if (dataTable.data !== undefined) {
            selectedRows.length === 0 ||
            selectedRows.every((el: any) => el.subRows === undefined)
                ? dataTable.data.forEach((item: any) => {
                      sumActive =
                          item.numberActive !== undefined
                              ? sumActive + item.numberActive
                              : sumActive;
                      sumArchive =
                          item.numberArchive !== undefined
                              ? sumArchive + item.numberArchive
                              : sumArchive;
                  })
                : selectedRows.forEach((item: any) => {
                      sumActive =
                          item.numberActive !== undefined
                              ? sumActive + item.numberActive
                              : sumActive;
                      sumArchive =
                          item.numberArchive !== undefined
                              ? sumArchive + item.numberArchive
                              : sumArchive;
                  });
        }
        setNumberMultiShopActive(sumActive);
        setNumberMultiShopArchived(sumArchive);
    };

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        let associatedLanguage: any[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el.shopId);
            for (let i in el.subRows) {
                idsLocalShop.push(el.subRows[i].Id);
                associatedLanguage.push(el.subRows[i]);
            }
        });
        setAssociatedLanguages(associatedLanguage);
        setShopRowData(selectedRows);
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setIsShopSelected(localShop.length !== 0);
        setShopsLanguagesData(dataTable.data);
        ActiveArchiveCount();
        // eslint-disable-next-line
    }, [selectedRows, dataTable.data, i18nextLng]);

    return (
        <React.Fragment>
            <div>
                <DynamicTable
                    key={key}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canMovedCheckboxLeftOnExpand
                    url={isArchivedLanguagesClicked ? urlArchive : urlActive}
                    setData={setDataTable}
                    canExpand={true}
                    canSelect
                    customSelect
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            isShopSelected={isShopSelected}
                            setShopId={setShopId}
                            selectedRows={selectedRows}
                            setDataIsUpdated={setGlobalDataIsUpdated}
                            isHistoryModalOpened={isHistoryModalOpened}
                            setIsHistoryModalOpened={setIsHistoryModalOpened}
                            setLocalFilterActive={setLocalFilterActive}
                            setIsArchivedLanguagesClicked={
                                setIsArchivedLanguagesClicked
                            }
                            isArchivedLanguagesClicked={
                                isArchivedLanguagesClicked
                            }
                        />
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setGlobalDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={globalDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="language"
                />
            </div>
            {isHistoryModalOpened === true ? (
                <History
                    ids={selectedRows}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    rowData={rowData}
                    setDataShopIsUpdated={setGlobalDataIsUpdated}
                />
            ) : null}
            {isAssociateModalOpened ? (
                <LanguageAssociateModal
                    isArchivedLanguagesClicked={isArchivedLanguagesClicked}
                />
            ) : null}
            {isAssociatedLangues && !oneShop ? (
                <ConfirmationAssociatedMessage
                    isModalOpened={isAssociatedLangues}
                    setIsModalOpened={setIsAssociatedLangues}
                />
            ) : null}
            {isValidateShopArchiveOpened ? (
                <ConfirmationArchiveLanguage
                    selectedRows={selectedRows}
                    setLimit={setLimit}
                />
            ) : null}
            <NProgressWrapper isLoading={isLoading} />
        </React.Fragment>
    );
}
